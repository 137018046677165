import React, { useEffect, useState } from "react"
import { Script } from "gatsby"
import { Container } from "react-bootstrap"

const ElfSightReview = (props) => {
  const [renderComponent, setRenderComponent] = useState(false)
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

    setTimeout(() => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    }, 5000)
  }, [])
  return (
    <section className="elfsight-review-wrapper">
      <Container>
        <h2 className="pt-0">What our customers say about Chepstow</h2>
        <div class="elfsight-app-69641fe1-5198-43a2-ae22-6d50c0a19418" data-elfsight-app-lazy></div>

        <h2>What our customers say about Ross-on-Wye</h2>
        <div class="elfsight-app-0e8fd626-3fd5-4a7e-abbd-674213369140" data-elfsight-app-lazy></div>

        <h2>What our customers say about Monmouth</h2>
        <div class="elfsight-app-d64f0aec-796b-4dee-b387-0baa53f2aac5" data-elfsight-app-lazy></div>

        <h2>What our customers say about Usk</h2>
        <div class="elfsight-app-a52d9bde-53ce-40fd-88d8-f82785e3d785" data-elfsight-app-lazy></div>

        <h2>What our customers say about Forest of Dean</h2>
        <div class="elfsight-app-b94663a9-e099-4bd7-a7fc-c21f35b8c67a" data-elfsight-app-lazy></div>

        <h2>What our customers say about Newport</h2>
        <div class="elfsight-app-d8b3c468-b255-4025-b864-9a9435e814fb" data-elfsight-app-lazy></div>
      </Container>
      {renderComponent && <Script src="https://apps.elfsight.com/p/platform.js" strategy="idle" />}
    </section>
  )
}

export default ElfSightReview
